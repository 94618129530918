.profilePic {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.navTabs > div {
    width: 100px;
    text-align: center;
    cursor: pointer;
    padding: 1em;
    background-color: white;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
}

.navTabs > div:nth-child(1) {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.navTabs > div:last-child {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.navTabs > div:hover {
    background-color: lightgrey;
}

.navBar::after {
    content: "";
    display: block;
    position: absolute;
    background-color: darkgrey;
    height: 2px;
    width: 100%;
    bottom: 0px;
}