.activePortfolioNavItem > .portfolioNavButton {
    border: 1px solid black;
    /* font-weight: bold; */
    /* background: #00bd0d; */
    transform: translateY(0px) !important;

}

.activePortfolioNavItem:hover > .portfolioNavButton {
    background: transparent !important;
    box-shadow: 0px 0px 0px !important;
}

.portfolioHeaderNavItem {
    position: relative;
    cursor: pointer;
}

.portfolioHeaderNavItem:hover > .portfolioNavButton {
    transform: translateY(-5px);
    background: lightgreen;
    box-shadow: -1px 2px 3px;
}

.portfolioNavButton {
    transition: transform 1000ms, background 1000ms, box-shadow 1000ms;
    padding: 0 3px 0 3px;
    border-radius: 3px;
}