.cell {
    background: #fff;
    height:100px;
    width: 6rem;
    font-size: 4rem;
    text-align: center;
    border-radius: 0px;
    border: 1px solid black;
    cursor:pointer;
}

.topLeft {
    border-top-left-radius: 6px;
    border-top-color: grey;
    border-left-color: grey;
}

.top {
    border-top-color: grey;
}

.topRight {
    border-top-right-radius: 6px;
    border-top-color: grey;
    border-right-color: grey;
}

.right {
    border-right-color: grey;
}

.bottomRight {
    border-bottom-right-radius: 6px;
    border-right-color: grey;
    border-bottom-color: grey;
}

.bottom {
    border-bottom-color: grey;
}

.bottomLeft {
    border-bottom-left-radius: 6px;
    border-bottom-color: grey;
    border-left-color: grey;
}

.left {
    border-left-color: grey;
}