.tttBoard {
    text-align:center;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.tttCol {
    padding:0 !important;
}

.footerColCenter {
    text-align: center;
}

.turnActive {
    font-weight: bold;
}

.paddingor {
    padding: 0 !important;
}

.topRightor {
    border-top-right-radius: 0px !important;
}

.rowSpacing {
    margin-bottom: 10px;
}