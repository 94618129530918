.v4container {
    margin-top: 50px;
    z-index: 2;
}

.v4button {
    z-index: 3;
}

.v4playerText {
    color: lightgrey;
    position: relative;
    display: inline-block;
    font-weight: bold;
}

.winHalo {
    width:120%;
    background-color:lightblue;
    height:50px;
    position:absolute;
    top:-10px;
    left:-5px;
    z-index:-1;
    border-radius:50%;
    opacity: 0;

}