.paddingor {
    padding: 0 !important;
}

.rowSpacing {
    margin-bottom: 10px;
}

.topRightor {
    border-top-right-radius: 0px !important;
}