.cell {
    text-align:center;
    border: 1px solid #444444;
    width: 50px;
    height: 50px;
    z-index:5;
    cursor:pointer;
  }
  
  .cutout {
    clip-path: circle(20px at center);
  }